// src/components/Navbar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const storedMode = localStorage.getItem('darkMode');
        if (storedMode === 'true') {
            setDarkMode(true);
            document.documentElement.classList.add('dark');
        }
    }, []);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        if (!darkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('darkMode', 'true');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('darkMode', 'false');
        }
    };

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    return (
        <nav className="bg-gray-800 dark:bg-gray-900 fixed w-full z-50 shadow-md">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    {/* Logo */}
                    <div className="flex-shrink-0">
                        <Link to="/" className="text-white text-xl font-extrabold tracking-tight">
                            Vasilis Gkouzouris
                        </Link>
                    </div>
                    {/* Desktop Menu */}
                    <div className="hidden md:flex space-x-6">
                        <Link to="/" className="text-gray-300 hover:text-indigo-400 px-3 py-2 text-sm font-medium transition-colors duration-300">
                            Home
                        </Link>
                        <Link to="/about" className="text-gray-300 hover:text-indigo-400 px-3 py-2 text-sm font-medium transition-colors duration-300">
                            About
                        </Link>
                        <Link to="/portfolio" className="text-gray-300 hover:text-indigo-400 px-3 py-2 text-sm font-medium transition-colors duration-300">
                            Portfolio
                        </Link>
                    </div>
                    {/* Dark Mode Toggle and Mobile Menu Button */}
                    <div className="flex items-center">
                        <button
                            onClick={toggleDarkMode}
                            className="text-gray-300 hover:text-indigo-400 focus:outline-none mr-4"
                            aria-label="Toggle Dark Mode"
                        >
                            {darkMode ? <FaSun className="h-5 w-5" /> : <FaMoon className="h-5 w-5" />}
                        </button>
                        {/* Mobile menu button */}
                        <div className="md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                className="text-gray-300 hover:text-indigo-400 focus:outline-none"
                                aria-controls="mobile-menu"
                                aria-expanded={isOpen}
                            >
                                {!isOpen ? (
                                    <svg className="h-6 w-6" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                                    </svg>
                                ) : (
                                    <svg className="h-6 w-6" fill="none" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Menu */}
            {isOpen && (
                <div className="md:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <Link to="/" className="block text-gray-300 hover:text-indigo-400 px-3 py-2 rounded-md text-base font-medium">
                            Home
                        </Link>
                        <Link to="/about" className="block text-gray-300 hover:text-indigo-400 px-3 py-2 rounded-md text-base font-medium">
                            About
                        </Link>
                        <Link to="/portfolio" className="block text-gray-300 hover:text-indigo-400 px-3 py-2 rounded-md text-base font-medium">
                            Portfolio
                        </Link>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
