// src/App.js
import React, { useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ErrorBoundary from './components/ErrorBoundary';

const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Portfolio = lazy(() => import('./pages/Portfolio'));

function App() {
  useEffect(() => {
    ReactGA.initialize('UA-XXXXXXXXX-X'); // Replace with your GA ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
      <ErrorBoundary>
        <Router>
          <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-100 font-sans">
            <Helmet>
              <title>Vasilis Gkouzouris | Video Editor & Motion Designer</title>
              <meta
                  name="description"
                  content="Professional portfolio of Vasilis Gkouzouris showcasing video editing and motion graphics work."
              />
              <meta
                  name="keywords"
                  content="Video Editor, Motion Designer, Thessaloniki, Greece, Adobe Premiere, After Effects, Portfolio"
              />
              <meta name="author" content="Vasilis Gkouzouris" />
            </Helmet>
            <Navbar />
            <main className="flex-grow mt-16">
              <Suspense fallback={<div className="text-center mt-20">Loading...</div>}>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                </Routes>
              </Suspense>
            </main>
            <Footer />
          </div>
        </Router>
      </ErrorBoundary>
  );
}

export default App;
