// src/components/Footer.js
import React from 'react';
import {  FaVimeo, FaLinkedin, FaGithub ,  } from 'react-icons/fa';
import { SiUpwork } from "react-icons/si";



const Footer = () => (
    <footer className="bg-gray-800 dark:bg-gray-900 text-white py-6">
        <div className="container mx-auto px-4 flex flex-col md:flex-row items-center justify-between">
            {/* Social Links */}
            <div className="flex space-x-6 mb-4 md:mb-0">

                <a href="https://vimeo.com/shadowvgk" target="_blank" rel="noopener noreferrer"
                   className="hover:text-indigo-400 transition duration-300">
                    <FaVimeo className="h-5 w-5"/>
                </a>
                <a href="https://linkedin.com/in/vasilis-gkouzouris" target="_blank" rel="noopener noreferrer"
                   className="hover:text-blue-500 transition duration-300">
                    <FaLinkedin className="h-5 w-5"/>
                </a>
                <a href="https://github.com/rnsparkour" target="_blank" rel="noopener noreferrer"
                   className="hover:text-gray-400 transition duration-300">
                    <FaGithub className="h-5 w-5"/>
                </a>
                <a href="https://www.upwork.com/freelancers/~01ba8223f98bdc7e1b" target="_blank" rel="noopener noreferrer"
                   className="hover:text-indigo-400 transition duration-300">
                    <SiUpwork className="h-5 w-5"/>
                </a>
            </div>


            <div className="text-sm text-gray-400">
                &copy; {new Date().getFullYear()} Vasilis Gkouzouris. All rights reserved.
            </div>
        </div>
    </footer>
);

export default Footer;
